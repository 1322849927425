<template>
    <div id="sc-tickets-all">
        <div id="sc-header">
            <h3>Tickets All</h3>
        </div>

        <!-- Loading Spinner -->
        <div v-if="ticketsAll.length == 0" class="sc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>

        <!-- Table -->
        <v-client-table v-else :data="ticketsAll" :columns="columns" :options="options" />
    </div>
</template>

<script>
import _ from "lodash";
import APICalls from "./APICalls";
import util from "./../../util/util";

export default {
    name: "TicketsAll",
    data() {
        return {
            columns: ["id", "customer.company", "products", "linteItemsSku", "truck", "dateTime", "linteItemsQuantity", "carryOver", "quickbooksInvoice"],
            ticketsAll: [],
            options: {
                perPage: 50,
                columnsDropdown: true,
                orderBy: {
                    column: "id",
                    ascending: false,
                },
                headings: {
                    id: "#",
                    "customer.company": "Customer",
                    linteItemsSku: "SKU",
                    linteItemsQuantity: "Quantity",
                    dateTime: "Date",
                    quickbooksInvoice: "QB #",
                    carryOver: "Prepay",
                },
                templates: {
                    linteItemsSku(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, (lineitem) => {
                            res += lineitem.item.sku + "\n";
                        });
                        return res;
                    },
                    linteItemsQuantity(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, (lineitem) => {
                            res += lineitem.quantity + "\n";
                        });
                        return res;
                    },
                    carryOver(h, ticket) {
                        return ticket.customer.carryOver;
                    },
                    dateTime(h, ticket) {
                        return util.formatTimestamp(ticket.createdAt);
                    },
                },
            },
        };
    },
    methods: {
        async getTickets() {
            this.ticketsAll = [];
            try {
                let tickets = await APICalls.getTickets();
                this.ticketsAll = tickets;
            } catch (e) {
                console.log(e);
                this.$router.push({ path: "/login" });
            }
        },
    },
    watch: {
        ticketsAll: function(val) {
            _.each(val, (ticket) => {
                let products = "";
                _.each(ticket.lineitems, (obj) => {
                    products += obj.item.name + "\n";
                });
                ticket.products = products;
            });
        },
    },
    mounted() {
        this.getTickets();
    },
};
</script>

<style lang="scss">
#sc-tickets-all {
    #sc-header {
        background: transparent;
        box-shadow: none;
        display: flex;
    }

    .VueTables {
        .row {
            // Columns - Dropdown
            .dropdown {
                .dropdown-toggle {
                    background-color: #007bc4 !important;
                    height: 45px;
                }

                .dropdown-menu {
                    padding: 10px;
                    font-size: 18px;

                    input {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    table {
        tbody {
            tr {
                td {
                    white-space: pre;
                    word-wrap: break-word;
                }
                td:nth-child(2) {
                    font-size: 14px;
                }
                td:nth-child(4) {
                    font-size: 14px;
                }
                td:nth-child(5) {
                    max-width: 120px;
                    word-wrap: break-word;
                    overflow-wrap: break-word !important;
                    white-space: normal;
                }
                td:nth-child(6) {
                    font-size: 14px;
                }
                td:nth-child(7) {
                    text-align: center;
                }
            }
        }
    }
}
</style>
